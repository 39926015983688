import React, { useEffect, useState } from 'react';

import { useMsal } from '@azure/msal-react';

import SUBSCRIPTIONS_LOGO from '../../assets/images/subscriptions-logo.svg';
import appInsights from '../../common/applicationInsights';
import EventProcess from "../../common/trackedEventProcessEnum";
import EventTypes from "../../common/trackedEventTypeEnum";
import LoginComponent from '../../components/loginComponent/loginComponent';
import { LOGIN_SCOPES } from '../../msal/constants';
import settings from '../../settings/settings';
const login = () => {
    const { instance } = useMsal();
    const [loginHint, setLoginHint] = useState<string | undefined>();

    useEffect(() => {
        appInsights.trackEvent("User opened the login screen", EventTypes.OpenedPortal, EventProcess.Default);

        const urlParams = new URLSearchParams(location.search);
        const loginHintParam = urlParams.get('login_hint');
        if (loginHintParam) {
            setLoginHint(loginHintParam)
        }
    }, []);

    const onLoginClickCallback = () => {
        instance.loginRedirect({
            scopes: [...LOGIN_SCOPES()],
            prompt: 'login',
            loginHint,
            authority: loginHint ? settings.NoWorkAccountAuthority : settings.Authority
        });
    }

    return (
        <LoginComponent onLogin={onLoginClickCallback} logoSrc={SUBSCRIPTIONS_LOGO} />
    );
};

export default login;
