import { DataGrid } from 'devextreme-react';
import { Column } from 'devextreme-react/data-grid';
import { ColumnCellTemplateData } from 'devextreme/ui/data_grid';
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import Button from '../../components/button/button';
import Icon, { IconEnum } from '../../components/icon';
import Spinner, { ClipSpinner } from '../../components/spinner';
import ROUTES from '../../routes/routes';
import { SubscriptionUserDetailsDto, SysKitSubscriptionsClient, UserStatusEnum } from '../../services/service';
import InviteUserDialog from '../dialogs/inviteUserDialog/inviteUser';
import RemoveAccessDialog from '../dialogs/removeAccessDialog/removeAccess';
import './users.scss';

const STRINGS = {
    LOADING: 'Loading...',
    HEADER: 'Users',
    INVITE_USER: 'Invite User',
    ERROR_MESSAGE: 'An error occurred while fetching users. Please refresh the screen or contact support.',
    COLUMNS: {
        NAME: {
            CAPTION: 'Name'
        },
        EMAIL: {
            CAPTION: 'E-mail'
        },
        ROLE: {
            CAPTION: 'Role'
        },
        STATUS: {
            CAPTION: 'Status'
        }
    }
};

export const Users: React.FC = () => {
    const [loading, setLoading] = useState<boolean>(true);
    const [hasError, setHasError] = useState<boolean>(false);
    const { subscriptionId } = useParams() as any;
    const [users, setUsers] = useState<SubscriptionUserDetailsDto[]>([]);
    const [isInviteUserDialogOpen, setIsInviteUserDialogOpen] = useState<boolean>(false);
    const [isRemoveAccessDialogOpen, setIsRemoveAccessDialogOpen] = useState<boolean>(false);
    const [userDto, setUserDto] = useState<SubscriptionUserDetailsDto>();
    const [defaultInvitationEmail, setDefaultInvitationEmail] = useState<string>();
    const history = useHistory();

    const getUsersSubscription = async () => {
        setLoading(true);

        try {
            const syskitSubscriptionsClient = new SysKitSubscriptionsClient();
            const usersResponse = await syskitSubscriptionsClient.getUsers(subscriptionId);
            setUsers(usersResponse);
        } catch (error) {
            console.error(error);
            setHasError(true);
        }
        finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        getUsersSubscription();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    //#region Actions

    const onInviteUserClicked = async () => {
        setIsInviteUserDialogOpen(true);
    };

    const onInviteUserDialogClose = () => {
        setIsInviteUserDialogOpen(false);
        getUsersSubscription();
        setDefaultInvitationEmail('');
    };

    const onRemoveAccessSuccess = () => {
        setIsRemoveAccessDialogOpen(false);
        getUsersSubscription();
    }

    const onDeleteClicked = (user: SubscriptionUserDetailsDto) => {
        setUserDto(user);
        setIsRemoveAccessDialogOpen(true);
    };

    const onEditClicked = (user: SubscriptionUserDetailsDto) => {
        history.push(
            `${ROUTES.CONTROL_CENTER.ROUTE.replace(':subscriptionId', subscriptionId)}` +
            `/${ROUTES.CONTROL_CENTER.RELATIVE_LINKS.USER_EDIT.replace(':userId', user.id!)}`);
    };

    const onResendClicked = (user: SubscriptionUserDetailsDto) => {
        setDefaultInvitationEmail(user.email);
        setIsInviteUserDialogOpen(true);
    };

    //#endregion Actions

    const showLoader = loading;
    const showError = !loading && hasError;
    const showContent = !showLoader && !showError;

    return (
        <>
            {showLoader &&
                <Spinner text={STRINGS.LOADING} style={{ height: 400, width: '80%', minWidth: '400px' }}>
                    <ClipSpinner size={70} color={'var(--electric-indigo)'} />
                </Spinner>
            }
            {showError &&
                <div className="users__error">
                    <span>{STRINGS.ERROR_MESSAGE}</span>
                </div>
            }
            {showContent &&
                <div className="tw-flex tw-h-full tw-flex-col tw-p-[30px]">
                    <div className="users__header">
                        <h1>{STRINGS.HEADER}</h1>
                        <div>
                            <Button onClick={onInviteUserClicked}>{STRINGS.INVITE_USER}</Button>
                        </div>
                    </div>

                    <div className="users__grid tw-overflow-auto">
                        <DataGrid
                            height="100%"
                            dataSource={users}
                            showColumnLines={false}
                            showRowLines={false}
                            showBorders={false}
                            rowAlternationEnabled={true}
                        >
                            <Column
                                dataField="name"
                                caption={STRINGS.COLUMNS.NAME.CAPTION}
                                headerCellRender={renderCellHeader}
                                cellRender={renderNameCell} />
                            <Column
                                dataField="email"
                                caption={STRINGS.COLUMNS.EMAIL.CAPTION}
                                headerCellRender={renderCellHeader}
                                cellRender={renderEmailCell} />
                            <Column
                                dataField="role"
                                alignment="left"
                                caption={STRINGS.COLUMNS.ROLE.CAPTION}
                                headerCellRender={renderCellHeader}
                                cellRender={renderRoleCell} />
                            <Column
                                dataField="status"
                                alignment="left"
                                caption={STRINGS.COLUMNS.STATUS.CAPTION}
                                headerCellRender={renderCellHeader}
                                cellRender={renderStatusCell} />
                            <Column
                                alignment="right"
                                width={100}
                                cellRender={(cellData) => renderActionsCell(cellData, onDeleteClicked, onEditClicked, onResendClicked)} />
                        </DataGrid>
                    </div>

                    <InviteUserDialog
                        subscriptionId={subscriptionId}
                        isOpen={isInviteUserDialogOpen}
                        closeDialog={onInviteUserDialogClose}
                        onSuccess={onInviteUserDialogClose}
                        initialValue={defaultInvitationEmail} />
                    <RemoveAccessDialog
                        subscriptionId={subscriptionId}
                        user={userDto!}
                        isOpen={isRemoveAccessDialogOpen}
                        closeDialog={() => setIsRemoveAccessDialogOpen(false)}
                        onSuccess={onRemoveAccessSuccess} />
                </div>
            }
        </>
    );
}

//#region Cell Rendering

const renderCellHeader = (data: ColumnCellTemplateData) => {
    return (<b>{data.column.caption}</b>);
};

const renderNameCell = (cellData: ColumnCellTemplateData<SubscriptionUserDetailsDto>) => {
    return (cellData.data?.name ?? 'N/A');
};

const renderEmailCell = (cellData: ColumnCellTemplateData<SubscriptionUserDetailsDto>) => {
    return (!!cellData.data?.email
        ? <a href={`mailto:${cellData.data.email}`}>{cellData.data.email}</a>
        : 'N/A');
};

const renderRoleCell = (cellData: ColumnCellTemplateData<SubscriptionUserDetailsDto>) => {
    switch (cellData.data?.role) {
        case 0:
            return 'None';
        case 3:
            return 'Administrator';
        default:
            return `Unknown (${cellData.data?.role})`;
    }
};

const renderStatusCell = (cellData: ColumnCellTemplateData<SubscriptionUserDetailsDto>) => {
    switch (cellData.data?.status) {
        case UserStatusEnum.Active:
            return 'Active';
        case UserStatusEnum.InvitationPending:
            return 'Invited/Pending';
        case UserStatusEnum.InvitationExpired:
            return 'Invitation Expired';
        default:
            return `Unknown (${cellData.data?.role})`
    }
};

const renderActionsCell = (
    cellData: ColumnCellTemplateData<SubscriptionUserDetailsDto>,
    onDeleteAction: (data: SubscriptionUserDetailsDto) => void,
    onEditAction: (data: SubscriptionUserDetailsDto) => void,
    onResendInvitationAction: (data: SubscriptionUserDetailsDto) => void
) => {
    const showDeleteAction = !!cellData.data;
    const showEditAction = !!cellData.data
        && cellData.data.status === UserStatusEnum.Active;
    const showInvitationResendAction = !!cellData.data
        && cellData.data.status === UserStatusEnum.InvitationExpired;

    return (
        <>
            {showEditAction &&
                <Icon
                    className="users__grid__action"
                    iconName={IconEnum.Edit}
                    onClick={() => onEditAction(cellData.data!)} />
            }
            {showInvitationResendAction &&
                <Icon
                    className="users__grid__action"
                    iconName={IconEnum.Mail}
                    onClick={() => onResendInvitationAction(cellData.data!)} />
            }
            {showDeleteAction &&
                <Icon
                    className="users__grid__action"
                    iconName={IconEnum.Delete}
                    onClick={() => onDeleteAction(cellData.data!)} />
            }
        </>
    );
};

//#endregion Cell Rendering

