import classNames from 'classnames';
import React from 'react';
import './loading.scss';

//#region Interfaces
export interface LoadingProps {
    children?: React.ReactNode;
    className?: string;
}

//#endregion Interfaces

const Loading: React.FunctionComponent<LoadingProps> = props => {
    const loadingClassName = classNames('loading-component', props.className);

    return (
        <div className={loadingClassName}>
            {props.children}
            <div className="ellipsis-loading" data-testid="loading"></div>
        </div>
    );
};
export default Loading;
