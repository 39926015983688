import React from "react";

import SHAPES_PATTERN from '../../assets/images/login-shapes-pattern.svg';
import Button from "../button/button";

interface LoginComponentProps {
    onLogin(): void;
    logoSrc?: string;
}

const LoginComponent: React.FC<LoginComponentProps> = ({ onLogin, logoSrc }) => (
    <div className="tw-relative tw-z-0 tw-flex tw-h-full tw-items-center tw-justify-center tw-overflow-auto tw-bg-white sm:tw-overflow-hidden sm:tw-bg-space-cadet/6">
        <div className="tw-relative tw-flex tw-w-[530px] tw-max-w-full tw-flex-col tw-items-center tw-justify-center tw-rounded-[57px] tw-bg-white tw-px-20 tw-py-28 tw-text-center sm:tw-max-w-xl">
            <img
                src={SHAPES_PATTERN}
                className="tw-absolute tw-right-[calc(100%-30px)] -tw-z-10 tw-hidden tw-h-5/6 sm:tw-block"
            />
            <img src={logoSrc} className="tw-ml-[-60px] tw-max-w-[inherit] sm:tw-max-w-none" />
            <Button onClick={onLogin} className="tw-mt-24 tw-rounded-[32px] tw-px-16 tw-py-4 tw-shadow-md">
                Sign in
            </Button>
            <div className="tw-mt-6 tw-text-space-cadet">You will be redirected to the sign in page</div>
        </div>
    </div>
)

export default LoginComponent;